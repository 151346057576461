import {Box} from "@mui/material";
import  Home from './Components/Home';

const Function = () => {
    return(<>
        <Box sx={{backgroundColor : 'white'}}>
        <Home />
        </Box>
    </>);
};

export default Function;